import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import styles from './author.module.scss';

const Author = ({ content }) => {
  const defaultPic = useStaticQuery(graphql`
    query {
      authorPic: file(relativePath: { eq: "author-default.png" }) {
        childImageSharp {
          fixed(width: 62, height: 62) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const pic = content.pic || defaultPic.authorPic.childImageSharp.fixed;

  return (
    <div className={styles.container}>
      <Img className={styles.pic} fixed={pic} alt={styles.name} />
      <div className={styles.content}>
        {content.name ? <div className={styles.name}>{content.name}</div> : ''}
        {content.role ? <div className={styles.role}>{content.role}</div> : ''}
      </div>
    </div>
  );
};

Author.propTypes = {
  content: PropTypes.shape({
    pic: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
};

export default Author;
