import React from 'react';
import PropTypes from 'prop-types';

import styles from './tags.module.scss';

const Tags = ({ items }) => (
  <>
    {items && (
      <div className={styles.container}>
        <ul>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);

Tags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
};

Tags.defaultProps = {
  items: false,
};

export default Tags;
