import * as React from 'react';
import PropTypes from 'prop-types';

import styles from './wave.module.scss';

const Wave = ({ pathname }) => {
  const isHome = pathname === '/';
  const isServicios = pathname.startsWith('/servicios');
  const isOther = !isHome && !isServicios;

  if (isOther) {
    return (
      <svg className={styles.wave} width={2793} height={100} fill="none">
        <g
          clipPath="url(#default_svg__clip0)"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path
            d="M0 496.782h116.375c116.375 0 349.125 0 581.875 62.098s465.5 186.294 698.25 124.196c232.75-62.098 465.5-310.489 698.25-351.888 232.75-41.398 465.5 124.196 581.87 206.993L2793 620.978V0H0v496.782z"
            fill="#1C5188"
          />
          <path
            d="M0 591.246l116.375 14.077C232.75 619.401 465.5 647.555 698.25 633.478c232.75-14.077 465.5-70.386 698.25-70.386s465.5 56.309 698.25 84.463c232.75 28.155 465.5 28.155 581.87 28.155H2793V0H0v591.246z"
            fill="url(#default_svg__paint0_linear)"
            fillOpacity={0.8}
          />
        </g>
        <defs>
          <linearGradient
            id="default_svg__paint0_linear"
            x1={989.653}
            y1={279.669}
            x2={1846.5}
            y2={832.733}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1C5188" />
            <stop offset={1} stopColor="#009BDB" />
          </linearGradient>
          <clipPath id="default_svg__clip0">
            <path fill="#fff" d="M0 0h2793v100H0z" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (isServicios) {
    return (
      <svg className={styles.wave} width={2793} height={560} fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 420.418l116 11.701c117 11.702 350 35.105 582 58.508 233 23.403 466 45.97 699 45.97 232 0 465-22.567 698-22.567s465 22.567 582 34.269L2793 560V0H0v420.418z"
          fill="#1C5188"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 68.008l116 90.677c117 89.867 350 271.221 582 350.563 233 78.533 466 55.863 699 0 232-56.673 465-147.35 698-204.023s465-79.342 582-90.677l116-11.335V0H0v68.008z"
          fill="url(#services_svg__paint0_linear)"
          fillOpacity={0.8}
        />
        <defs>
          <linearGradient
            id="services_svg__paint0_linear"
            x1={1023}
            y1={118.222}
            x2={1838.7}
            y2={644.571}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1C5188" />
            <stop offset={1} stopColor="#009BDB" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  return (
    <svg className={styles.wave} width={2793} height={700} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 496.782h116.375c116.375 0 349.125 0 581.875 62.098s465.5 186.294 698.25 124.196c232.75-62.098 465.5-310.489 698.25-351.888 232.75-41.398 465.5 124.196 581.87 206.993L2793 620.978V0H0v496.782z"
        fill="#1C5188"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 591.246l116.375 14.077C232.75 619.401 465.5 647.555 698.25 633.478c232.75-14.077 465.5-70.386 698.25-70.386s465.5 56.309 698.25 84.463c232.75 28.155 465.5 28.155 581.87 28.155H2793V0H0v591.246z"
        fill="url(#homepage_svg__paint0_linear)"
        fillOpacity={0.8}
      />
      <defs>
        <linearGradient
          id="homepage_svg__paint0_linear"
          x1={989.653}
          y1={279.669}
          x2={1846.5}
          y2={832.733}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C5188" />
          <stop offset={1} stopColor="#009BDB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

Wave.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default Wave;
