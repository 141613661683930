import React from 'react';
import PropTypes from 'prop-types';

import styles from './prelude.module.scss';

const Prelude = ({ hasDarkBackground, content }) => (
  <header
    className={hasDarkBackground ? styles.containerDarkBg : styles.container}
  >
    {content.title && <h2 className={styles.title}>{content.title}</h2>}
    {content.pretitle && (
      <h3 className={styles.pretitle}>{content.pretitle}</h3>
    )}
    {content.description && (
      <p className={styles.description}>{content.description}</p>
    )}
  </header>
);

Prelude.propTypes = {
  hasDarkBackground: PropTypes.bool,
  content: PropTypes.shape({
    pretitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

Prelude.defaultProps = {
  hasDarkBackground: false,
};

export default Prelude;
