const slideInFromBottom = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  hidden: {
    opacity: 0,
    y: 80,
  },
};

const containerShowOnLanding = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.2,
    },
  },
};

const itemShowOnLanding = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
};

const containerMobileMenuBg = {
  closed: {
    display: 'none',
    transition: {
      delay: 0.8,
      duration: 0.8,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
  opened: {
    display: 'block',
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const itemMobileMenuBg = {
  closed: {
    height: '0%',
    transformOrigin: 'right top',
    skewY: 2,
    transition: {
      duration: 0.8,
      ease: 'easeInOut',
    },
  },
  opened: {
    height: '100%',
    transformOrigin: 'right top',
    skewY: 0,
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
    },
  },
};

const containerMobileMenuLinks = {
  hidden: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: -1,
    },
  },
  visible: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};

const itemMobileMenuLinks = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  hidden: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 0.1,
      ease: 'easeOut',
    },
  },
};

export {
  slideInFromBottom,
  containerShowOnLanding,
  itemShowOnLanding,
  containerMobileMenuBg,
  itemMobileMenuBg,
  containerMobileMenuLinks,
  itemMobileMenuLinks,
};
