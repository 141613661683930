import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import styles from './cta-button.module.scss';

const CTAButton = ({ to, text }) => (
  <motion.div
    className={styles.ctaButton}
    whileHover={{
      boxShadow: '0 4px 15px 0 rgba(0, 0, 0, .20)',
      transition: { duration: 0.2 },
    }}
  >
    <Link to={to} duration={0.3}>
      {text}
    </Link>
  </motion.div>
);

CTAButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default CTAButton;
