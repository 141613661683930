// Temporary component: Under the hood, Gatsby uses GraphQL static
// queries to retrieve images.A static query being static you cannot
// pass the name of the image you want to retrieve(the Gatsby team is
// currently working on a fix).So, the workaround at the moment is
// to create an extra component which gets all the assets and only
// return the one that you call.

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const MdxImage = ({ src, alt }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid(maxWidth: 246, quality: 90) {
              ...GatsbyImageSharpFluid
              originalName
            }
          }
        }
      }
    }
  `);

  const image = allImageSharp.edges.find(
    (edge) => edge.node.fluid.originalName === src.replace('./images/', '')
  );

  return image ? <Img fluid={image.node.fluid} alt={alt} /> : null;
};

MdxImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default MdxImage;
