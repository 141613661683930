import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Prelude from '../molecules/prelude';
import CTAButton from '../atoms/cta-button';
import styles from './footer.module.scss';

const Footer = ({ hasCTA }) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <Prelude
        hasDarkBackground
        content={{
          pretitle: 'Expertos en diseño y desarrollo web',
          title: '¿Listo para unir fuerzas con nosotros?',
          description:
            'Empecemos a trabajar juntos en tu nuevo proyecto y llevémoslo al siguiente nivel.',
        }}
      />
      {hasCTA && (
        <div className={styles.cta}>
          <CTAButton to="/contacto/" text="Pídenos presupuesto" />
        </div>
      )}
      <div className={styles.smallText}>
        <div className={styles.smallText__copyright}>
          © 2020 JR Web. Todos los derechos reservados.
        </div>
        <nav className={styles.smallText__links}>
          <ul>
            <li>
              <Link to="/aviso-legal/">Aviso legal</Link>
            </li>
            <li>
              <Link to="/politica-de-cookies/">Política de cookies</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  hasCTA: PropTypes.bool,
};

Footer.defaultProps = {
  hasCTA: true,
};

export default Footer;
