import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Wave from '../atoms/wave';
import CookieBanner from '../molecules/cookie-banner';
import Header from '../organisms/header';
import Footer from '../organisms/footer';
import styles from './base.module.scss';

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  return (
    <>
      <CookieBanner />
      <div className={styles.container}>
        <Wave pathname={location.pathname} />
        <Header menuLinks={data.site.siteMetadata.menuLinks} />
        <main className={styles.main}>{children}</main>
        <Footer hasCTA={!location.pathname.startsWith('/contacto')} />
      </div>
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
