import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import {
  containerMobileMenuBg,
  itemMobileMenuBg,
  containerMobileMenuLinks,
  itemMobileMenuLinks,
} from '../../utils/animations';

import styles from './mobile-menu.module.scss';

const MobileMenu = ({ menuLinks, isMenuOpen, setMenuOpen }) => {
  const [isLinkClicked, setLinkClicked] = useState(false);
  const showAnimation = isMenuOpen && !isLinkClicked;

  return (
    <motion.div
      className={styles.container}
      initial="closed"
      animate={showAnimation ? 'opened' : 'closed'}
      exit="closed"
      variants={containerMobileMenuBg}
    >
      <motion.div
        className={styles.secondaryColorLayer}
        variants={itemMobileMenuBg}
      />
      <motion.div className={styles.menuLayer} variants={itemMobileMenuBg}>
        <nav>
          <motion.ul
            animate={showAnimation ? 'visible' : 'hidden'}
            initial="hidden"
            variants={containerMobileMenuLinks}
          >
            {menuLinks.map((link, i) => (
              <motion.li key={link.name} variants={itemMobileMenuLinks}>
                <Link
                  className="menu-item"
                  activeClassName={styles.active}
                  to={link.link}
                  onClick={() => {
                    setLinkClicked(true);
                    setTimeout(() => {
                      setLinkClicked(false);
                      setMenuOpen(false);
                    }, 100);
                  }}
                >
                  {link.name}.<div className={styles.number}>0{i + 1}</div>
                </Link>
              </motion.li>
            ))}
          </motion.ul>
        </nav>
      </motion.div>
    </motion.div>
  );
};

MobileMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMenuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
};

MobileMenu.defaultProps = {
  isMenuOpen: false,
};

export default MobileMenu;
