import React from 'react';
import PropTypes from 'prop-types';

import Author from './author';
import styles from './testimonial.module.scss';

const Testimonial = ({ withoutPanel, content }) => (
  <div className={styles.container}>
    <blockquote
      className={withoutPanel ? styles.testimonialNoPanel : styles.testimonial}
    >
      <p>{content.quote}</p>
      <footer>
        <Author content={content.author} />
      </footer>
    </blockquote>
  </div>
);

Testimonial.propTypes = {
  withoutPanel: PropTypes.bool,
  content: PropTypes.shape({
    quote: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
  }).isRequired,
};

Testimonial.defaultProps = {
  withoutPanel: false,
};

export default Testimonial;
