import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import styles from './logo.module.scss';

function JrwebLogo(props) {
  const [showDash, setDashState] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setDashState((showDash) => !showDash);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const variants = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.1,
      },
    },
  };

  return (
    <svg
      className={styles.logo}
      width={146}
      height={68}
      viewBox="0 0 146 68"
      fill="none"
      {...props}
    >
      <path
        d="M20.249 66.031L2 34.417 20.249 2.803H56.75L75 34.417 56.751 66.03H20.25z"
        stroke="#fff"
        strokeWidth={2}
      />
      <path
        d="M13.52 62.7c-2.013.034-4.02-.21-5.967-.722V53.58a19.95 19.95 0 004.764.66c2.216 0 3.802-.69 4.758-2.07.955-1.382 1.433-3.546 1.433-6.495V1h10.11v44.608c0 5.565-1.271 9.805-3.813 12.72-2.541 2.914-6.303 4.372-11.285 4.372zM42.141 38.968v19.78H31.226V7.213h15.007c7.001 0 12.183 1.275 15.545 3.824 3.363 2.55 5.042 6.421 5.038 11.617a13.655 13.655 0 01-2.496 8.072 16.542 16.542 0 01-7.087 5.554c7.755 11.57 12.807 19.057 15.158 22.46h-12.13L47.946 38.964l-5.805.005zm0-8.884h3.527c3.46 0 6.01-.577 7.652-1.73 1.642-1.153 2.465-2.964 2.469-5.43 0-2.442-.842-4.18-2.525-5.218-1.683-1.038-4.286-1.55-7.809-1.534h-3.314v13.912z"
        fill="#fff"
        stroke="#1C5188"
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <path
        d="M78.049 57.8l-5.244-13.34h2.645l3.611 8.832 3.841-8.832h2.829l4.071 8.855 3.381-8.855h2.645L90.814 57.8H88.79l-4.462-9.89-4.255 9.89h-2.024zM101.163 58a2.882 2.882 0 01-2.116-.874 2.882 2.882 0 01-.874-2.116v-7.36c0-.828.291-1.533.874-2.116a2.882 2.882 0 012.116-.874h7.59c.828 0 1.533.291 2.116.874a2.88 2.88 0 01.874 2.116v4.922h-11.086v2.093c0 .337.054.567.161.69.123.107.353.161.69.161h10.235V58h-10.58zm-.506-7.751h8.579v-2.254c0-.337-.054-.56-.161-.667-.107-.123-.33-.184-.667-.184h-6.9c-.337 0-.567.061-.69.184-.107.107-.161.33-.161.667v2.254zM115.242 58V40.29h2.484v4.37h8.096c.828 0 1.533.291 2.116.874a2.88 2.88 0 01.874 2.116v7.36a2.88 2.88 0 01-.874 2.116 2.882 2.882 0 01-2.116.874h-10.58zm3.335-2.484h6.9c.337 0 .56-.054.667-.161.123-.123.184-.353.184-.69v-6.67c0-.337-.061-.56-.184-.667-.107-.123-.33-.184-.667-.184h-6.9c-.337 0-.567.061-.69.184-.107.107-.161.33-.161.667v6.67c0 .337.054.567.161.69.123.107.353.161.69.161z"
        fill="#fff"
      />
      <motion.path
        initial="visible"
        animate={showDash ? 'visible' : 'hidden'}
        variants={variants}
        d="M131.242 60.507v-2.484h14v2.484h-14z"
        fill="#F2AE35"
      />
    </svg>
  );
}

export default JrwebLogo;
