import React from 'react';
import PropTypes from 'prop-types';
import MdxImage from '../../utils/mdx-image';
import Prelude from '../molecules/prelude';
import Tags from '../molecules/tags';

import styles from './case-study-list.module.scss';

const CaseStudyList = ({ content }) => (
  <section className={styles.container}>
    {content.title && (
      <Prelude
        content={{
          title: content.title,
        }}
      />
    )}
    <div className={styles.items}>
      {content.items.map((item, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.content}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
          <div className={styles.tags}>
            <Tags items={item.tags} />
          </div>
          <div className={styles.image}>
            <MdxImage src={item.image} alt={item.imageAlt} />
          </div>
        </div>
      ))}
    </div>
  </section>
);

CaseStudyList.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        imageAlt: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
      }).isRequired
    ),
  }).isRequired,
};

export default CaseStudyList;
