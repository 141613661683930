import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Logo from '../atoms/logo';
import CTAButton from '../atoms/cta-button';
import MobileMenu from '../molecules/mobile-menu';
import styles from './header.module.scss';

const Header = ({ menuLinks }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <nav className={styles.mainMenu}>
          <ul>
            {menuLinks.map((link) => (
              <li key={link.name}>
                <Link to={link.link} activeClassName={styles.active}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className={styles.cta}>
          <CTAButton to="/contacto/" text="Pídenos presupuesto" />
        </div>

        <div
          className={styles.burger}
          style={{ position: isMenuOpen ? 'fixed' : 'absolute' }}
        >
          <button type="button" onClick={() => setMenuOpen(!isMenuOpen)}>
            <i
              className={isMenuOpen ? styles.burgerOpened : styles.burgerClosed}
            />
            <i
              className={isMenuOpen ? styles.burgerOpened : styles.burgerClosed}
            />
          </button>
        </div>

        <MobileMenu
          menuLinks={menuLinks}
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  menuLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Header;
