import React from 'react';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

import styles from './cookie-banner.module.scss';

const Banner = () => (
  <CookieConsent
    disableStyles
    containerClasses={styles.container}
    buttonText="Cerrar"
    acceptOnScroll
  >
    Utilizamos <Link to="/aviso-legal/">cookies</Link> para asegurar la mejor
    experiencia al usuario en nuestra web. Si sigues utilizando este sitio
    asumiremos que estás de acuerdo.
  </CookieConsent>
);

export default Banner;
